import styled from '@emotion/styled/macro'

export const AnalyticsInner = styled.div`
  margin-top: -20px;
  position: relative;

  &.loader:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
  }
  @media ${({ theme }) => theme.media.medium} {
    margin: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1.9rem;
  flex-wrap: wrap;
  a {
    @media ${({ theme }) => theme.media.medium} {
      order: 2;
    }
  }

  @media ${({ theme }) => theme.media.large} {
    padding: 2.3rem 1.6rem;
  }

  @media ${({ theme }) => theme.media.medium} {
    padding: 1.2rem 0;
  }
`

export const Wrap = styled.div`
  width: 100%;
  order: 3;
  @media ${({ theme }) => theme.media.medium} {
    width: auto;
    margin: 0;
    order: 1;
  }
`

export const LogoWrap = styled.div`
  @media (max-width: 768px) {
    order: 2;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  order: 2;

  button {
    margin-left: 1.6rem;

    @media screen and (max-width: 1110px) {
      font-size: 1rem;
      min-width: 0;
      padding: 0.5rem 1.5rem;
    }
    @media ${({ theme }) => theme.media.medium} {
      display: none;
    }
  }

  img {
    margin-left: 0.8rem;
  }
`

export const FilterByDate = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: 'Helvetica', sans-serif;
  margin-left: 2.8rem;
  cursor: pointer;

  img {
    margin-left: 1.2rem;
    @media ${({ theme }) => theme.media.medium} {
      margin: 0;
    }
  }

  @media screen and (max-width: 1110px) {
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.media.medium} {
    margin-left: 0;
  }

  span {
    @media ${({ theme }) => theme.media.medium} {
      display: none;
    }
  }
`

export const GotoBack = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  margin-top: 6.6rem;
  margin-left: 5.7rem;
  font-size: 1.2rem;
  @media ${({ theme }) => theme.media.medium} {
    margin: 0;
  }

  img {
    transform: rotate(-90deg);
    margin-right: 1.1rem;
  }

  span {
    @media ${({ theme }) => theme.media.medium} {
      display: none;
    }
  }
`

export const Share = styled.div`
  display: none;
  @media ${({ theme }) => theme.media.medium} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.title.h1}
  font-size: 4.4rem;
  line-height: 5.2rem;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  margin-top: 3.6rem;
  margin-bottom: 1.6rem;

  @media ${({ theme }) => theme.media.medium} {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
`

export const SubTitle = styled.div`
  ${({ theme }) => theme.body.p1};
  font-size: 2rem;
  text-align: center;
  margin-bottom: 4.2rem;

  @media ${({ theme }) => theme.media.medium} {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

export const Row = styled.div<{ double: boolean; small?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-family: 'Helvetica', sans-serif;
  padding: 0 1.9rem;
  canvas {
    margin: 0 auto;
    width: 100% !important;
    height: auto !important;
  }

  ${({ double, small }) => {
    if (small)
      return `canvas {
      max-width: 60%;
    }`
    if (!double)
      return `canvas {
      max-width: 78%;
    }`
    return `canvas {
      max-width: 75%;
    }`
  }}

  @media ${({ theme }) => theme.media.large} {
    flex-direction: column;
    ${({ double, small }) => {
      if (small) {
        return `canvas {
          max-width: 50%;
        }`
      }
      if (!double) {
        return `canvas {
          max-width: 100%;
        }`
      }

      return `canvas {
        max-width: 100%;
      }`
    }}

  @media ${({ theme }) => theme.media.medium} {
    padding: 0;
    ${({ double }) => {
      if (!double)
        return `canvas {
      max-width: none;
    }`
      return `canvas {
      max-width: none;
    }`
    }}
  }
`

export const Item = styled.div<{ double: boolean }>`
  width: ${({ double }) => (double ? '50%' : '100%')};
  margin-bottom: 11.6rem;
  @media ${({ theme }) => theme.media.large} {
    width: 100%;
    margin-bottom: 5rem;
  }
`

export const Name = styled.div`
  ${({ theme }) => theme.title.h1};
  font-weight: 400;
  display: inline-block;
  width: auto;
  align-items: center;
  position: relative;
  margin-bottom: 2.4rem;

  img {
    position: relative;
    margin-left: 1.1rem;
    cursor: pointer;
    display: inline-block;
    @media ${({ theme }) => theme.media.medium} {
      margin: 0 0 0 0;
      top: 3px;
    }
  }

  @media ${({ theme }) => theme.media.medium} {
    font-size: 2.4rem;
    line-height: normal;
    width: 100%;
    text-align: center;
  }
`

export const TooltipContent = styled.div`
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // тень для тултипа
  z-index: 10;
  width: 250px;
  top: 0;
  left: 150%;
  color: #000;

  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  // Стрелка для экранов >= 738px
  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: -9px;
    border-width: 7px 10px 7px 0;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
    display: block;
    filter: drop-shadow(-1px 2px 3px rgba(0, 0, 0, 0.1)); // тень для стрелки
  }

  @media (max-width: 738px) {
    top: 140%;
    left: auto;
    right: -10px;
    width: 200px;

    // Стрелка для экранов < 738px
    &::before {
      top: -19px;
      left: auto;
      right: 15px;
      border-width: 10px 7px 10px 7px;
      border-color: #ffffff transparent transparent transparent;
      filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.1)); // тень для стрелки
      transform: rotate(180deg);
    }
  }
`

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const PdfContainer = styled.div``
